<div class="m-base pb-5">
    <div class="bg-white radius-min mt-5  ">
        <div class="row text-center pt-2 pt-2 pb-2 text-siras" style="justify-content: center;">
        <b>Avvisiamo la clientela che Siras S.a.S resterà chiusa per la pausa estiva<br>
            da sabato  10/08/2024 a lunedì  26/08/2024 compresi
             </b></div>
    </div>
</div>
<section class="container text-center mt-5">
    <img class="img-fluid w-250" src="/assets/img/siras-oleodinamica-logo.png">
</section>
<div class="m-base pb-5">
    <div class="bg-white radius-min mt-5  ">
        <div class="row text-center pt-2" style="justify-content: center;">
            <div class="col-lg-2 base mx-3 ">
                <a [routerLink]="'/progettazione'">
                    <p class="text-siras titolo text-uppercase ">Progettazione</p>
                    <img src="\assets\img\progettazione-siras-oleodinamica.jpg" alt="siras oleodinamica pavia"
                        class="img-fluid radius-min bordo-3d image">
                    <div class="overlay">
                        <div class="text "><span class="material-icons text radius-min">
                                mouse
                            </span></div>
                    </div>
                </a>
            </div>
            <div class="col-lg-2 base mx-3">
                <a [routerLink]="'/assemblaggi'">
                    <p class="text-siras titolo text-uppercase">Assemblaggi</p>
                    <img src="\assets\img\assemblaggi-siras-oleodinamica.jpg" alt="siras oleodinamica pavia"
                        class="img-fluid radius-min bordo-3d image">
                    <div class="overlay">
                        <div class="text "><span class="material-icons text radius-min">
                                settings
                            </span></div>
                    </div>
                </a>
            </div>
            <div class="col-lg-2 base mx-2">
                <a [routerLink]="'/riparazioni'">
                    <p class="text-siras titolo text-uppercase">Riparazioni</p>
                    <img src="\assets\img\riparazioni-siras-oleodinamica.jpg" alt="siras oleodinamica pavia"
                        class="img-fluid radius-min bordo-3d image">
                    <div class="overlay">
                        <div class="text "><span class="material-icons text radius-min">
                                build
                            </span></div>
                    </div>
                </a>
            </div>
            <div class="col-lg-2 base mx-2">
                <a [routerLink]="'/vendita'">
                    <p class="text-siras titolo text-uppercase">Vendita</p>
                    <img src="\assets\img\vendita-siras-oleodinamica.jpg" alt="siras oleodinamica pavia"
                        class="img-fluid radius-min bordo-3d image">
                    <div class="overlay">
                        <div class="text "><span class="material-icons text radius-min">
                                shopping_basket
                            </span></div>
                    </div>
                </a>
            </div>
            <!-- <div class="col-lg-2 base mx-2">
        <a [routerLink]="'/miller-dpi'">
        <p class="text-siras titolo text-uppercase">Miller DPI</p>
        <img src="\assets\img\miller-dpi-siras-oleodinamica.png" alt="siras oleodinamica pavia" class="img-fluid radius-min bordo-3d image">
        <div class="overlay">
            <div class="text "><span class="material-icons text radius-min">
                engineering
                </span></div>
          </div>
        </a>
    </div> -->
        </div>
        <p class="lead text-siras mt-5 p-4">
            Siras, presente sul mercato dal 1977, è in grado di soddisfare ogni esigenza, offrendo alla propria
            clientela massima disponibilità, prezzi concorrenziali ed una qualificata preparazione tecnica.<br><br>

            L'attività di Siras si distingue in quattro fasi essenziali: progettazione, assemblaggi, riparazioni e
            vendita.<br>
            Questi servizi permettono di intervenire tempestivamente, garantendo al cliente soluzioni ottimali in tempi
            brevissimi.<br><br>

            La vasta gamma di apparecchiature e accessori, disponibili presso il punto vendita, ha come valido supporto
            un attrezzato laboratorio ed un'officina, dove personale specializzato progetta e realizza pezzi speciali su
            misura.
        </p>
        <div class="bg-siras row m-0">
            <div class="col-lg-6 mt-5">
                <img class="img-fluid w-20 p-3" src="/assets/img/siras-oleodinamica-logo-2.png">
            </div>
            <div class="col-lg-6">
                <p class="lead text-white text-right p-3" style="font-size: 17px;">
                    Siras S.a.S - S.S. 35 Milano-Genova - Via Turati, 16<br>
                    San Martino Siccomario (PV) P.IVA: 00211280185<br>
                    <a href="https://www.iubenda.com/privacy-policy/39661139" target="_blank"
                        class=" text-white iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe "
                        title="Privacy Policy ">Privacy Policy</a>
                    <script
                        type="text/javascript">(function (w, d) { var loader = function () { var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src = "https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s, tag); }; if (w.addEventListener) { w.addEventListener("load", loader, false); } else if (w.attachEvent) { w.attachEvent("onload", loader); } else { w.onload = loader; } })(window, document);</script>
                    <a href="https://www.iubenda.com/privacy-policy/39661139/cookie-policy" target="_blank"
                        class="text-white iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe m-4"
                        title="Cookie Policy ">Cookie Policy</a>
                    <script
                        type="text/javascript">(function (w, d) { var loader = function () { var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src = "https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s, tag); }; if (w.addEventListener) { w.addEventListener("load", loader, false); } else if (w.attachEvent) { w.attachEvent("onload", loader); } else { w.onload = loader; } })(window, document);</script>
                    <a href="https://www.blu-system.com/" class="text-white" target="_blank">Powered by Blu System</a>
                </p>
            </div>
        </div>
    </div>
</div>